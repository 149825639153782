<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Bons De Sorties ( Validés )    </h4> 



                    <div class="col">
                                    <b-button pill size="sm"  class="m-2 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
                        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button v-if="d_gst_sorti_val==true" pill size="sm"  class="mr-2 float-right" variant="outline-warning" @click="devalider_click(row.item)">
                                <i class="fa fa-check"></i>
                        
                    </b-button>
                    <b-button v-if="d_gst_sorti_val==true" pill size="sm" class="mr-2 float-right" variant="outline-info" @click="edit_devalider_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-info" @click="Ckick_exportDox(row.item)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-info" @click="fetchListArts(row.item)">
                            <i class="fa fa-bars"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
                        <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>


    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >

        
        </b-table>
    </b-modal>

    <!-- les trucs public -->

<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {
    computed: {
    rows_pagin() {
        return this.itemslist.length
    },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
    d_gst_sorti_val() {
      return this.$store.getters.get_gst_sorti_val;
    },
    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
                        perPage: 10,
            currentPage: 1,
           fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],

            curentBN:'',

            itemslist: [],

            fields: [
                {key: 'nom', label: 'N°', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                {key: 'valider', label: 'Valider', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{
        Ckick_exportDox(rowitem)
{
                    this.isLoading=true;
                     this.$http.get('/detailbonSorti/'+rowitem.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                //telecharger rapp
                                this.exportDox(rowitem)

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
},

exportDox(rowitem)
{

let datrw =[new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Réference",
                                        spacing: {
                                        before: 100,
                                        after: 100
                                    },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 6000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Description",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Quantité",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Lot",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),



            ],
        })]

this.ArticlesList.forEach(function(item){

let r = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.ref,
                                  spacing: {
                                            before: 100,
                                            after: 100,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 6000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.description,
                                        spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.qte.toString(),

                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.nom_lot,
                                                                          spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


            ],
        })

  datrw.push(
 r
)
});

        const doc = new Document();

const table = new Table({

    rows:  datrw
})



        doc.addSection({


            properties: {
                page: {
                    pageNumbers: {
                        start: 1,
                        formatType: PageNumberFormat.DECIMAL,
                    },
                },
            },
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({
                            children: [
                    new TextRun({
                        text: "تعاونية حليب الريش",
                        color: '636572',
                        bold: true,

                        })
                            ],
                        border: {
                           bottom: {
                                color: "636572",
                                space: 4,
                                value: "single",
                                size: 10,
                            }},
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER,
                        }),
                    ],
                }),
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    children: ["Page : ", PageNumber.CURRENT],
                                }),
                                new TextRun({
                                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                                }),
                            ],
                        }),
                    ],
                }),
            },



            children: [

                new Paragraph({



                    children: [

                        new TextRun({
                        text: "Bon De Sorties",
                        color: '636572',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    ],

                    heading: HeadingLevel.HEADING_1,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 200,
                            before: 200
                        },
                }),




                new Paragraph({



                    children: [

                        new TextRun({
                        text: "N° : "+rowitem.nom,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: "          Dépôt : "+rowitem.depot,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI Light"
                    })
                    ],

                    heading: HeadingLevel.HEADING_5,
                    alignment: AlignmentType.LEFT,

                        spacing: {
                            after: 100,
                            before: 100
                        },
                }),
                
                

                new Paragraph({



                    children: [
                        new TextRun({
                        text: "De la part de : ",
                        color: '5d83a0',
                        bold: false,
                        font: "Segoe UI"
                    }),
                        new TextRun({
                        text: rowitem.user,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI Light"
                    }),
                    new TextRun({
                        text: "          Date : ",
                        color: '5d83a0',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: rowitem.date,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI Light"
                    })
                    ],

                    heading: HeadingLevel.HEADING_5,
                                        alignment: AlignmentType.LEFT,

                        spacing: {
                            after: 300,
                            before: 100
                        },
                }),

                table
                
                ]
        })







    Packer.toBlob(doc).then(blob => {

  saveAs(blob, rowitem.nom+".docx");

    });

},
        edit_devalider_click(item) {

                this.$confirm("Voulez-vous vraiment devalider et modifier ce Bon ??")
                 .then(() => {

                        this.BnDevalider(item.id,true)


                })
                .catch(error=> {
                console.log(error)

                });


      },
        devalider_click(item) {

                                this.$confirm("Voulez-vous vraiment Devalider ce Bon??")
                                .then(() => {

                                    this.BnDevalider(item.id,false)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
      BnDevalider(id_bn,edit){

            this.isLoading=true;

                this.$http.get('/validerSorties/'+id_bn+'/0')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon Devalidé',
                message: 'Les données sont devalidées correctement'
                })

                    if(edit)
                    {
                                            this.$router.push({ 
                                            name: 'bonSortie', 
                                            params: { 
                                            ResivedId: id_bn
                                            } 
                                            });
                    }
                    else
                    {
                                    let index = this.itemslist.findIndex(item => item.id === id_bn)
                                    this.itemslist.splice(index, 1)

                    }




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de devalider les données .. Réessayez !!'
                })
                });

        },

        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/bnSortieType/1/'+this.user_dep)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        fetchListArts(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailbonSorti/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
                NewBon()
        {
                    this.$router.push({ 
                        name: 'menuSorties'
                    });
        },

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>