<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-file-alt fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Commandes Dépôts</h2>
</div>




<div  v-if="d_gst_cmd_dep==true" class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_new">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Nouvelle Commande</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-plus-square fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>





<div  v-if="d_gst_suivi_cmd_dep==true" class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_brouillon">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Suivi Des commandes</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-list-alt fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>




  </div>
</template>

<script>
export default {
computed: {
    d_gst_cmd_dep() {
      return this.$store.getters.get_gst_cmd_dep;
    },

    d_gst_suivi_cmd_dep() {
      return this.$store.getters.get_gst_suivi_cmd_dep;
    },
},
 methods:{
       click_new()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'cmdDep'

                    });
      },
      click_brouillon()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'suiviCmdDep'

                    });
      }


 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>