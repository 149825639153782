<template>
  

<div>

 <b-modal  id="lolmodal" :title="article_ref"  size="lg" hide-footer>






           <form @submit.prevent="handleSubmitLot()"> 



            <div class="row">
                
            <div class="form-group col">

                            <label for="depot_id">Dépôt</label>
                            <select class="form-control" id="depot_id" v-model="itemFormLot.depot_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormLot.depot_id.$error }">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormLot.depot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>

            <div class="form-group col">
                <label for="qte">Quantité</label>
                <input type="text" v-model="itemFormLot.qte" id="qte" name="qte" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormLot.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemFormLot.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemFormLot.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
            </div>



            <div v-if="is_mangLot" class="row">
            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date Exp</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormLot.date_exp"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" 
                    @mousedown="changeDataExp"/>
            </div>

            <div class="form-group col">

             <b-button    class="ml-2 float-left p-0" variant="link" @click="changeLot">
                <i class="fa fa-retweet"></i>
                <span> </span> 
            </b-button>
                <label for="nom" class="ml-2">  Nom</label>
                <input type="text" v-model="itemFormLot.nom" id="nom" name="nom" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormLot.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemFormLot.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>
            </div>



            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
            </div>

        </form>









            <b-table 
        sort-icon-left
        small 
        sticky-header
        :fields="fieldsLot" 
        :items="LotsList"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>

              <div class="card border-info">
                        <div class=" d-flex justify-content-center pt-1 text-info">
                                <h5 class="font-weight-light ">Quantité Globale : {{total_price}} </h5>
                        </div>
              </div>

</b-modal>


    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";


export default {
 props: ['LotsList','depots','article_id','article_ref','is_mangLot'],

      computed: {


      total_price: function() {
      return this.LotsList.reduce((a, b) => +a + +b.qte, 0);
    }
  },
	data () {
		return {
            
            itemFormLot: {
                    id:'',
                    nom:'',
                    qte:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    date_exp:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    depot_id:'',
                    article_id:''},

            fieldsLot: [
                {key: 'nom', label: 'Lot', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                {key: 'qte', label: 'Quantité', sortable: true},
                {key: 'date', label: 'Date Création', sortable: true},
                {key: 'date_exp', label: 'date Expiration', sortable: true},
                { key: 'actions', label: '' }
            ],

            isSubmitted: false,
            isLoading: false,

            isLotGst: false
		}
	},
    validations: {
            itemFormLot: {
                nom: {
                    required
                },
                qte: {
                    required,
                    decimal
                },
                depot_id: {
                    required
                },
                article_id: {
                    required
                }
            }

    },
    methods :{
changeLot()
{

this.itemFormLot.nom = "Lot_"+this.itemFormLot.date_exp 
},

        del_click(itm) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ?? Attention si cet élément est déjà utilisé ,vous NE pouvez PAS le supprimer")
                 .then(() => {


            this.isLoading=true;

            this.$http.delete('/lots/'+itm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.LotsList.findIndex(item => item.id === itm.id)


                   this.LotsList.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });
                                })

                  //erreur de dialog              
                .catch(error=> {
                console.log(error)

                });




      },
              handleSubmitLot() {

            if(!this.is_mangLot)
            {
            this.itemFormLot.date_exp='2200-1-1',
            this.itemFormLot.nom='Par Defaut'
            }

                this.itemFormLot.article_id=this.article_id


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {





            this.isLoading=true;

            this.$http.post('/lots',{
                   
                    nom:this.itemFormLot.nom,
                    qte:this.itemFormLot.qte,
                    date:this.itemFormLot.date,
                    date_exp:this.itemFormLot.date_exp,
                    depot_id:this.itemFormLot.depot_id,
                    article_id:this.itemFormLot.article_id,



                })
                .then(response => {

                this.isLoading=false;


this.LotsList.unshift(response.data.data);


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

                }

            },

        
    }

    ,
    components: {
              Loading,
             
    }
}
</script>

<style>

</style>