<template>
  

<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">


        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Bon De Déplacement</h4> 
            
        </div>


          <div class="row m-4">

        <div class="col-md-8 ml-auto mr-auto">

           <form @submit.prevent="handleSubmitBonDeplc()">

            <div class="row  mt-2">
            <div class="form-group col">
                <label for="qte">Bon :</label>
                <input :disabled="nom_dis" type="text" v-model="itemFormBon.nom" id="qte" name="qte" class="form-control"
                 :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.nom.$error }"/>
                 
                <div v-if="isSubmitted && !$v.itemFormBon.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>


            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormBon.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            </div>



            <div class="row mt-2">
            <div class="form-group col">

                            <label for="depot_id">Dépôt Source :</label>
                            <select :disabled="deps_dis" class="form-control" id="depot_id" v-model="itemFormBon.depot_sourc_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.depot_sourc_id.$error }">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.depot_sourc_id.required" class="invalid-feedback">Entrez la source (Différente de la destination) !!</div>

            </div>


                        <div class="form-group col">

                            <label for="depot_destin_id">Dépôt Destination :</label>
                            <select :disabled="deps_dis" class="form-control" id="depot_destin_id" v-model="itemFormBon.depot_destin_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.depot_destin_id.$error }">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.depot_destin_id.required" class="invalid-feedback">Entrez la destination (Différente de la source) !!</div>

            </div>
            </div>


            <div class="form-group mt-2">
            <label for="adresse">Note</label>
            <textarea name="adresse" id="adresse"  rows="2" class="form-control" v-model="itemFormBon.description"></textarea>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>

        </form>

        </div>   

</div> 

    
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
 props: ['ResivedId'],

computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },

  },
mounted(){

this.fetchDepots()

if(this.ResivedId)
{
this.process_modification(this.ResivedId)
}
else
{
this.process_Ajouter()
}

    },
data () {
		return {

            depots:[],
            itemFormBon: {
                    id:"",
                    nom:'___',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    valider:false,
                    description:'',
                    depot_sourc_id:'',
                    depot_destin_id:'',
                    user:'user'},

            isSubmitted: false,
            isLoading: false,
            isModification:false,


            //boninfos Champs disibl:
            nom_dis:true,
            deps_dis:false,


		}
    },
    validations: {
            itemFormBon: {
                nom: {
                    required
                },
                date: {
                    required
                },
                valider: {
                    required
                },
                depot_sourc_id: {
                    required
                },
                depot_destin_id: {
                    required
                },
                user: {
                    required
                }
            }

    },
methods:{

        process_modification(id_resived)
        {
                    this.isLoading=true;
                    this.$http.get('/bnDeplac/'+id_resived)
                    .then((result) => {
                    
                    //affectation des information
                    this.itemFormBon.id=result.data.data.id,
                    this.itemFormBon.nom=result.data.data.nom,
                    this.itemFormBon.date=result.data.data.date,
                    this.itemFormBon.valider=result.data.data.valider,
                    this.itemFormBon.description=result.data.data.description,
                    this.itemFormBon.depot_sourc_id=result.data.data.depot_sourc_id,
                    this.itemFormBon.depot_destin_id=result.data.data.depot_destin_id,
                    this.itemFormBon.user=result.data.data.user




                    //boninfos Champs disibl:
                        this.nom_dis=false,
                        this.deps_dis=true,


                     //Activer la modification
                     this.isModification=true,


                     //desible load
                     this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        process_Ajouter()
        {

                    //boninfos Champs disibl:
                        this.nom_dis=true,
                        this.deps_dis=false,

                     //Activer la modification
                     this.isModification=false

        }
        ,

        fetchDepots: function () {

                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmitBonDeplc(){

                //USER HANDLE
                this.itemFormBon.user= this.user_name


                if(this.itemFormBon.depot_sourc_id==this.itemFormBon.depot_destin_id)
                {
                        this.itemFormBon.depot_sourc_id=''
                        this.itemFormBon.depot_destin_id=''

                }


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                    if(this.isModification)
                    {
                
                    this.edit_bon()
                    }
                    else
                    {
                    
                    this.add_bon()
                    }
   

                }


        },
        add_bon()
        {

            this.isLoading=true;

            this.$http.post('/bnDeplac',{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    valider:this.itemFormBon.valider,
                    description:this.itemFormBon.description,
                    depot_sourc_id:this.itemFormBon.depot_sourc_id,
                    depot_destin_id:this.itemFormBon.depot_destin_id,
                    user:this.itemFormBon.user,
                })
                .then(response => {
                    //metre a jour id
                    this.itemFormBon.id=response.data.data.id,
                    this.itemFormBon.nom=response.data.data.nom,

                    //boninfos Champs disibl:
                    this.nom_dis=false,
                    this.deps_dis=true,


                    //Activer la modification
                    this.isModification=true,


                    this.isLoading=false;
                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '


                })
                    //afficher les details:

                let src = this.depots.findIndex(item => item.id === response.data.data.depot_sourc_id)
                let dest = this.depots.findIndex(item => item.id === response.data.data.depot_destin_id)


                    this.preparerDetail(
                    response.data.data.id,
                    response.data.data.nom,
                    response.data.data.depot_sourc_id,
                    response.data.data.depot_destin_id,
                    this.depots[src].nom,
                    this.depots[dest].nom,
                    )

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        edit_bon()
        {

            this.isLoading=true;

            this.$http.patch('/bnDeplac/'+this.itemFormBon.id,{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    valider:this.itemFormBon.valider,
                    description:this.itemFormBon.description,
                    depot_sourc_id:this.itemFormBon.depot_sourc_id,
                    depot_destin_id:this.itemFormBon.depot_destin_id,
                    user:this.itemFormBon.user,
                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data.data);


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })

                //afficahge detail
                    let src = this.depots.findIndex(item => item.id === response.data.data.depot_sourc_id)
                    let dest = this.depots.findIndex(item => item.id === response.data.data.depot_destin_id)


                    this.preparerDetail(
                    response.data.data.id,
                    response.data.data.nom,
                    response.data.data.depot_sourc_id,
                    response.data.data.depot_destin_id,
                    this.depots[src].nom,
                    this.depots[dest].nom,
                    )

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        preparerDetail(id_bon,nom,sourc,destin,Nsouc,Ndestin)
        {
                        this.$router.push({ 
                        name: 'BonDeplacDetail', 
                        params: { 
                        idBon: id_bon,
                        nomBon: nom,
                        idDepS: sourc,
                        idDepD: destin,
                        Nsouc:Nsouc,
                        Ndestin:Ndestin
                        } 
                            });
        }

        



},
components: {
              Loading      
}


}
</script>

<style>

</style>