<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">

            <div class="row justify-content-centre align-items-center">    
               
            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-sticky-note"></span> Gestion Des Catégories    </h4> 
               
             
<div class="col">
                <b-button pill size="sm"  class="m-3 float-right" variant="success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>

                <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchList">
                    <i class="fa fa-sync-alt"></i>
                    <span> Actualiser</span> 
                </b-button>
</div>
            </div>
       



        <b-table 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
      </div>


    <!-- le modal -->
    <b-modal id="banqModal" :title="itemForm.nom"  hide-footer>
           <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="name">Nom Catégorie</label>
                <input type="text" v-model="itemForm.nom" id="name" name="name" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>



            <div class="form-group ">
                            <label for="centre">Type Catégorie</label>
                            <select class="form-control" id="centre" v-model="itemForm.type"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.type.$error }">
                            <option value="Interne">Internes</option>
                            <option value="Externes">Externes</option>
                            <option value="Services Centre">Services Centre</option>

                            </select>

                            <div v-if="isSubmitted && !$v.itemForm.type.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="is_charg"
                    v-model="itemForm.is_charg"
                    name="is_charg"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="is_charg">Produits De Charge</label>
            </div>

            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="4" class="form-control" v-model="itemForm.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {

    mounted(){
        this.fetchList()
    },
	data () {
		return {
            itemslist: [],
            itemForm: {
                    id:'',
                    nom:'',
                    type:'',
                    is_charg:false,
                    description:''},
            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'type', label: 'Type', sortable: true},
                {key: 'is_charg', label: 'Charge', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                },
                type: {
                    required
                },
                is_charg: {
                    required
                }

            }
    },
    methods :{


        itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/categories',{
            nom:this.itemForm.nom,
            type:this.itemForm.type,
            is_charg:this.itemForm.is_charg,
            description:this.itemForm.description

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/categories/'+this.itemForm.id,{
            nom:this.itemForm.nom,
            type:this.itemForm.type,
             is_charg:this.itemForm.is_charg,
            description:this.itemForm.description

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/categories/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.itemForm.id=''
        this.itemForm.nom=''
        this.itemForm.type=''
        this.itemForm.is_charg=false
        this.itemForm.description=''
        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
      },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                this.itemForm.nom=item.nom
                this.itemForm.type=item.type
                this.itemForm.is_charg=item.is_charg
                this.itemForm.description=item.description

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                this.itemForm.nom=item.nom
                this.itemForm.type=item.type
                this.itemForm.description=item.description
                                    this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>