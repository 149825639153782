<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Suivi Des Commandes Des Dépôt<span class="ml-2 text-success"> </span> </h5>
    </div>

                <div class="col-5 p-0">
                        <b-button pill @click="fetchDepotsCount"  class=" ml-0 mr-3 pt-1 pb-1 pr-2 pl-2 float-right" variant="outline-primary">
                        <i class="fa fa-sync-alt"></i>     

                        <span> Actualiser</span>           
                        </b-button> 
                </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">



                <div class="col-5 h-100 overflow-auto mt-2 mr-0 ml-0">
                            <div class="btn card border border-light shadow">

                                    <div class=" d-flex justify-content-center pt-1 text-info mb-2">
                                            <h5 class="font-weight-light ">Liste Des Commandes </h5>
                                    </div>

                                    <b-table 
                                    sort-icon-left
                                    small 
                                    responsive 

                                    :fields="fieldDepot" 
                                    :items="listDepot"
                                    >
                                        <template #cell(actions)="row">
                                                <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="DellDep_click(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                    
                                                </b-button>

                                                


                                                <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="depot_click(row.item)">
                                                            <i class="fa fa-bars"></i>
                                                    
                                                </b-button>
                                        </template>
                                    
                                    </b-table>

                            </div>
                </div>


                <div class="col h-100 overflow-auto mt-2 mr-2 ml-0">



                            
                <div class="btn card border border-light shadow mt-0">
                        <div class=" d-flex justify-content-center pt-1 text-info mb-2">
                                <h5 class="font-weight-light ">{{currentDepot}} </h5>
                        </div>

                        <b-table 
                        sort-icon-left
                        small 
                        responsive 

                        :fields="fieldsArtCaise" 
                        :items="listArticle"
                        >

                        
                        </b-table> 

                </div>


                       


                </div>

  </div>









      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';



export default {


 
    computed: {
            user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    }
    },
    mounted(){
            this.fetchDepotsCount()
    },
    data () {
		return {

            listDepot: [],
            fieldDepot: [
                {key: 'nom', label: 'Dépôt'},
                {key: 'countart', label: 'Nombre Des Articles'},
                { key: 'actions', label: '' }
            ],
            listArticle: [],
            fieldsArtCaise: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'description', label: 'Description'},
                {key: 'date', label: 'Date'},
                {key: 'user', label: 'Utilisateur'}
            ],


            currentDepot:'',

            isSubmitted: false,
            isLoading: false
		}
    },
    methods:{
        fetchDepotsCount()
        {
                    this.isLoading=true;
                    this.$http.get('/cmdDepotCount')
                    .then((result) => {
                                this.listDepot = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        depot_click(item)
        {
                    
                    this.isLoading=true;
                    this.$http.get('/cmdDepotLst/'+item.id)
                    .then((result) => {
                                this.listArticle = result.data.data
                                this.isLoading=false;
                                    
                                    //le nom ui                              
                                    this.currentDepot= "Dépôt : " +item.nom

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        DellDep_click(item)
        {
            this.$confirm("Voulez-vous vraiment supprimer cette commande ??")
                 .then(() => {

            this.isLoading=true;

            this.$http.get('/dellCmdDepot/'+item.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.listDepot.findIndex(item => item.id === item.id)

                    this.listDepot.splice(index, 1)


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },



 


        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>