import { render, staticRenderFns } from "./ListBonEntre.vue?vue&type=template&id=28b2c190&scoped=true&"
import script from "./ListBonEntre.vue?vue&type=script&lang=js&"
export * from "./ListBonEntre.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b2c190",
  null
  
)

export default component.exports