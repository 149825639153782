<template>
  

<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">


        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Commande De Dépôt</h4> 
            
        </div>
        
          <div class="row m-4">

        <div class="col-md-8 ml-auto mr-auto">

           <form @submit.prevent="handleSubmitBonCMD()">


            <div class="row mt-2">


                         <div class="form-group col">

                            <label for="depot_id">Mon Dépôt :</label>
                            <select class="form-control" id="depot_id" v-model="itemFormBon.depot_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.depot_id.$error }">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.depot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                         </div>
            </div>



            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>

        </form>

        </div>   

</div> 

    
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },

  },
mounted(){

this.fetchDepots()

    },
data () {
		return {

            depots:[],
            itemFormBon: {
                    depot_id:''},

            isSubmitted: false,
            isLoading: false,




        


		}
    },
    validations: {
            itemFormBon: {
                depot_id: {
                    required
                },
            }

    },
methods:{


        fetchDepots: function () {


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmitBonCMD(){


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                    let dep = this.depots.findIndex(item => item.id === this.itemFormBon.depot_id)

                        this.$router.push({ 
                        name: 'cmdDepDetail', 
                        params: { 
                        idDep:this.itemFormBon.depot_id,
                        nDep:this.depots[dep].nom
                        } 
                        });

                }


        }

        



},
components: {
              Loading      
}


}
</script>

<style>

</style>